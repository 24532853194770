<template>
  <div class="smartLink">
    <div class="title">
      <h2> AF Simulation</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form v-model="formData" :inline="true">
              <el-form-item label="Pkgs" label-width="100px">
                <el-input v-model="formData.pkg" placeholder="pkg here..."></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getDataList(1)">In Browser</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="addDataListClick">Add</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="delDataListClick">Delete</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          height="70vmin"
          stripe
          border
          :data="dataList"
          highlight-current-row
        >
          <el-table-column type="selection" align="center" width="55"></el-table-column>
          <el-table-column label="OfferId" prop="offerId" align="center" min-width="100">
            <template slot-scope="scope">
              <span @click="detailClick(scope.row)" class="cor337ab7">{{ scope.row.offerId }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Pkg"
            prop="pkg"
            align="center"
            min-width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Country"
            prop="country"
            align="center"
            min-width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Tracking Link"
            prop="trackingLink"
            align="center"
            min-width="110"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Attr Goals"
            prop="attrGoals"
            align="center"
            min-width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="OpenTime"
            prop="openTime"
            align="center"
            min-width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="CloseTime"
            prop="closeTime"
            align="center"
            min-width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="Status" prop="status" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.status === 1 ? '开启' : '关闭' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Operator"
            prop="createBy"
            align="center"
            min-width="110"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Create Time"
            prop="createTime"
            min-width="180"
            align="center"
          ></el-table-column>
          <el-table-column
            label="Operate Time"
            prop="updateTime"
            min-width="180"
            align="center"
          ></el-table-column>
          <el-table-column label="Option" min-width="180" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="updateClick(scope.row)"
                >Update</el-button
              >
              <el-button type="danger" size="small" @click="delRowClick(scope.row)"
                >Delete</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageParam.pageSize"
          :currentPage="pageParam.page"
        ></pagination>
      </el-card>
      <!-- 添加模态框 -->
      <el-dialog
        :visible.sync="addSimulationVisible"
        :close-on-click-modal="false"
        :append-to-body="true"
        :title="`${isUpdate ? 'Update' : 'Add'} Simulation`"
      >
        <el-form label-position="left" :model="addSimulation" label-width="100px">
          <el-row>
            <el-col :lg="11" :md="20">
              <el-form-item label="offerId:">
                <el-input v-model="addSimulation.offerId" placeholder="offerId here..."></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="20" :offset="1">
              <el-form-item label="pkg:">
                <el-input v-model="addSimulation.pkg" placeholder="pkg here..."></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :lg="11" :md="20">
              <el-form-item label="Country:">
                <el-input v-model="addSimulation.country" placeholder="country here..."></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="20" :offset="1">
              <el-form-item label="Tracking Link:">
                <el-input
                  v-model="addSimulation.trackingLink"
                  placeholder="Tracking Link here..."
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :lg="11" :md="20">
              <el-form-item label="Attr Goals:">
                <el-input
                  v-model="addSimulation.attrGoals"
                  placeholder="Attr Goals here..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="20" :offset="1">
              <el-form-item label="Status:">
                <el-select v-model="addSimulation.status" placeholder="请选择" style="width:210px;">
                  <el-option
                    v-for="item in statusOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :lg="11" :md="20">
              <el-form-item label="OpenTime" label-width="100px" prop="openTime">
                <el-time-select
                  placeholder="open Time"
                  v-model="addSimulation.openTime"
                  :picker-options="{
                    start: '00:00',
                    step: '00:02',
                    end: '23:59',
                  }"
                >
                </el-time-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="20" :offset="1">
              <el-form-item label="CloseTime" label-width="100px" prop="closeTime">
                <el-time-select
                  placeholder="Close Time"
                  v-model="addSimulation.closeTime"
                  :picker-options="{
                    start: '00:00',
                    step: '00:02',
                    end: '23:59',
                  }"
                >
                </el-time-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addSimulationVisible = false">取 消</el-button>
          <el-button type="primary" @click="changeSimulation">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 删除提示框 -->
      <el-dialog title="Delete" :visible.sync="delVisible" width="300px" center append-to-body>
        <div class="del-dialog-cnt">Confirm to delete ?</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="delVisible = false">cancel</el-button>
          <el-button type="primary" @click="delRow">confirm</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import {
    getSimulationListApi,
    changeSimulationApi,
    deleteSimulationApi,
  } from 'api/product/afSimulation';
  import Pagination from '@/components/pagination';
  import { myMixin } from '@/mixins/mixins.js';

  let clickTimer = null;
  const defaultAfSimulate = {
    status: 1,
  };
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    data() {
      return {
        formData: {
          pkg: '',
        },
        listLoading: false,
        addSimulationVisible: false,
        dataList: [],
        total: null,
        pageParam: {
          page: 1,
          pageSize: 10,
        },
        addSimulation: {
          status: 1,
        },
        updateId: null,
        isUpdate: false,
        dataSelectionList: [],
        delArr: [],
        statusOption: [
          {
            label: '关闭',
            value: 0,
          },
          {
            label: '开启',
            value: 1,
          },
        ],
        delVisible: false,
        simulationId: -1,
      };
    },
    mounted() {
      this.getDataList();
    },
    methods: {
      getDataList(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        let param = {
          ...this.smartLinks,
          page: this.pageParam.page,
          pageSize: this.pageParam.pageSize,
        };

        getSimulationListApi(param).then((response) => {
          if (response.code == 200) {
            this.dataList = response.result;
            this.total = response.total;
          } else {
            this.$message.error(response.status.msg);
          }
        });
      },
      addDataListClick() {
        this.isUpdate = false;
        this.addSimulation = Object.assign({}, defaultAfSimulate);
        this.addSimulationVisible = true;
      },
      handleDataTagsSelectionChange(val) {
        this.dataSelectionList = val;
      },
      delDataListClick() {
        const length = this.dataSelectionList.length;
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        }).then(() => {
          for (let i = 0; i < length; i++) {
            this.delArr.push(this.dataSelectionList[i].id);
          }

          deleteSimulationApi(this.delArr.join()).then((response) => {
            if (response.code == 200) {
              this.$message({
                message: 'Delete Success',
                type: 'success',
              });
              this.getDataList();
            } else {
              this.$message.error('Delete Error:' + response.message);
            }
          });
        });
        this.delArr = [];
      },
      delRowClick(row) {
        this.simulationId = row.id;
        this.delVisible = true;
      },
      delRow() {
        if (this.simulationId === -1) {
          return;
        }
        deleteSimulationApi(this.simulationId).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getDataList();
            this.delVisible = false;
          } else {
            this.$message.error('Error' + response.message);
          }
        });
      },
      updateClick(row) {
        this.isUpdate = true;
        this.simulationId = row.id;
        this.addSimulation = {
          ...row,
        };
        this.addSimulationVisible = true;
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      async handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.getDataList();
      },
      changeSimulation() {
        let params = {
          ...this.addSimulation,
        };
        let message = 'Add';
        if (this.isUpdate) {
          message = 'Update';
          params.id = this.simulationId;
        }
        changeSimulationApi(params).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: `${message} Success`,
              type: 'success',
            });
            this.getDataList();
          } else {
            this.$message.error(`${message} Error:` + response.message);
          }
        });
        this.addSimulationVisible = false;
        this.isUpdate = false;
      },
      detailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/offer/detail',
              query: {
                offerId: row.offerId,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
