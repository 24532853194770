import request from 'utils/request';

const api = {
  SIMULATION_LIST: '/api/af/simulation/list',
  SIMULATION_CHANGE: '/api/af/simulation/addOrUpdate',
  SIMULATION_DELETE: '/api/af/simulation/delete',
};

export function getSimulationListApi(params) {
  return request({
    url: api.SIMULATION_LIST,
    method: 'get',
    params,
  });
}

export function changeSimulationApi(data) {
  return request({
    url: api.SIMULATION_CHANGE,
    method: 'post',
    data,
  });
}
export function deleteSimulationApi(ids) {
  return request({
    url: api.SIMULATION_DELETE,
    method: 'delete',
    params: {
      ids,
    },
  });
}
